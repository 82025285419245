export default [
  {
    title: 'Início',
    route: 'home',
    icon: 'HomeIcon',
    acesso: ''
  },
  {
    title: 'Pedidos',
    route: 'pedidos',
    icon: 'TrelloIcon',
    acesso: ''
  },
  {
    title: 'Financeiro',
    route: 'financeiro',
    icon: 'DollarSignIcon',
    acesso: ''
  },
  {
    title: 'Tabela de Preços',
    route: 'tabela',
    icon: 'FileTextIcon',
    acesso: ''
  },
  {
    title: 'Usuários',
    route: 'usuarios',
    icon: 'UsersIcon',
    acesso: ''
  },
]
