<template>
  <b-nav-item @click="abreModal">
    <feather-icon
        :badge="countNovasMensagens"
        badge-classes="bg-success"
        size="21"
        icon="MessageCircleIcon"
        class="text-success"

    />
    <ChatModal/>
  </b-nav-item>

</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import ChatModal from '@/views/chat/Chat.vue'

export default {
  props: ["countNovasMensagens"],
  components: {
    BNavItem,
    ChatModal,
  },
  methods: {
    abreModal() {
      this.$bvModal.show('chat')
    }
  }
}
</script>
