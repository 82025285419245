<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <b-col lg="4" md="6" sm="12">
        <v-select
            class="style-chooser"
            @input="alterouLaboratorio"
            :options="laboratorios"
            label="nome"
            inputId="id"
            :clearable="false"
            :reduce="labo => labo.id"
            :value="selecionado"
        >
          <div slot="no-options">Modelo não encontrado!</div>
        </v-select>
      </b-col>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <chat :countNovasMensagens="countNovasMensagens"/>
<!--      <NotificationDropdown/>-->
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.user.user.name}}
            </p>
            <span class="user-status" v-if="$store.state.user.user.tipo_usuario == 1">
              Admin
            </span>
            <span class="user-status" v-else>
              Convidado
            </span>
          </div>
          <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="$store.state.user.user.url ? `https://smartprotese.s3.sa-east-1.amazonaws.com/smartprotese/${$store.state.user.user.url}` : '' "
              class="badge-minimal"
              badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'alterar-foto-perfil'}">
          <feather-icon
              size="16"
              icon="EditIcon"
              class="mr-50"
          />
          <span>Foto Perfil</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'alterar-senha-usuario'}">
          <feather-icon
              size="16"
              icon="LockIcon"
              class="mr-50"
          />
          <span>Alterar Senha</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'logout' }">
          <feather-icon size="16" icon="LogOutIcon"  class="mr-50"/>
          <span >Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BFormSelect, BCol,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Chat from '@core/layouts/components/app-navbar/components/Chat.vue'
//import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import { mapState, mapGetters } from 'vuex'
import laboratorios from '@/store/laboratorios'


export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormSelect,
    BCol,
    vSelect,

    // Navbar Components
    DarkToggler,
   // NotificationDropdown,
    Chat,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    if(!laboratorios.length) {
      this.$store.dispatch('laboratorios/listaLaboratorios')
    }
  },
  data() {
    return {
      selected: this.selecionado
    }
  },
  computed: {
    ...mapState({
      laboratorios: state => state.laboratorios.laboratorios
    }),
    ...mapGetters('laboratorios', ['selecionado']),
    ...mapGetters('chat', ['countNovasMensagens']),
  },
  methods: {
    alterouLaboratorio(val) {
      this.$store.commit('laboratorios/STORE_LABO_SELECIONADO', val)
      this.$store.commit('laboratorios/STORE_CONFIG_LABO_SELECIONADO',  {
        odonto_etapas: val.odonto_etapas,
        odonto_financeiro: val.odonto_financeiro,
      })
    }
  }
}
</script>

<style >
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: rgb(222, 234, 249);
  border: none;
  color: #166bc0;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #717485;
}
.style-chooser .vs__state-active {
  color: #fff;
}

</style>
