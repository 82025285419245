<template>
  <div class="item" v-bind:class="{ativo: mensagemAtiva}">
    <b-avatar
      size="36"
      class="cursor-pointer badge-minimal border-white float-left mr-1"
      :text="avatar"
      variant="secondary"
      badge
      :badge-variant="novaMensagem > 0 ? 'success' : 'secondary'"
    />
    <div class="title is-6">
      <strong class="text-primary">{{ laboratorio }}</strong> <span v-if="ultimaMensagem" class="text-muted">({{ ultimaMensagem }})</span>
    </div>
    <div class="subtitle is-6">{{ nomeDoUsuario }}</div>
  </div>
</template>

<script>
import { BAvatar, BBadge, } from "bootstrap-vue";
export default {
  props:['nomeDoUsuario','ultimaMensagem','mensagemAtiva', 'avatar', 'novaMensagem', 'laboratorio'],
  components: {
  BAvatar,
  BBadge,
  name: 'ItemDaListaDeConversa',
  },
}
</script>

<style>
  .subtitle{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

  }
  .item{
      padding: 15px 20px;
      margin-bottom: 0 !important;
  }
  .item:hover{
      background: #F5F5F5;
      cursor: pointer;
  }
  .item.ativo{
    background: linear-gradient(118deg, #0679fd, rgba(6, 121, 253, 0.7));
    color: white;
  }
</style>
