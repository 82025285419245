<template>
  <div class="mensagem" v-bind:class="{verde: verde}">
    {{conteudo}}
    <span :style="verde ? 'color: white;' : ''">{{horario}}</span>
  </div>
</template>
<script>
export default {
  name: "MensagemDaConversaAtiva",
  props: ['conteudo', 'horario', 'verde']
};
</script>

<style>
.mensagem{
    font-size: 1rem;
    box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
    background: white;
    margin: 10px 60px;
    padding: 10px 14px;
    border-radius: 5px;
    width: fit-content;
}
.mensagem.verde{
    background: linear-gradient(118deg, #0679fd, rgba(6, 121, 253, 0.7));
    color: white;
    align-self:  flex-end;
}
.mensagem span{
    margin-left: 25px;
    font-size: 11px;
    color: gray;
}
</style>